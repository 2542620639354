<template>
  <div>
    <v-card
        class="mb-5"
        elevation="3"
        v-for="(reserva, index) in reservas" :key="index">

      <v-card-title>{{ reserva.titulo }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="text-left font-weight-bold">{{ reserva.fecha | moment('DD/MM/YYYY') }} {{
              reserva.hora_inicio
            }}
          </v-col>
          <v-col class="text-right font-weight-bold">{{ $t(reserva.estado.descripcion) }}</v-col>
        </v-row>
        <v-row>
          <v-col>{{ reserva.instalacion.descripcion }}</v-col>
        </v-row>
      </v-card-text>

      <v-card-actions v-if="reserva.estado.id === 2">
        <v-btn
            color="primary"
            text
            @click="cancelar_reserva(reserva)"
        >
          {{ $t('Cancelar') }}
        </v-btn>
      </v-card-actions>

    </v-card>
  </div>
</template>

<script>
import show_toolbar_mixin from "@/mixins/show_toolbar_mixin";
import send_stats_mixin from "../mixins/send_stats_mixin";

export default {
  name: 'Reservas',
  mixins: [show_toolbar_mixin, send_stats_mixin],

  data() {
    return {
      reservas: []
    }
  },

  methods: {
    async cancelar_reserva(reserva) {
      const resp = await this.$confirm(
          this.$t('¿Estás segur@ que quieres cancelar la reserva {tituloReserva}?',
              { tituloReserva: reserva.titulo }),
          "",
          'question'
      )
      if (resp) {
        try {
          await this.$store.dispatch('reserva/cancel_reserva', reserva.id)
          this.$notify({
            title: this.$t('Atención {usuario}', { usuario: this.$store.state.auth.user.nombre }),
            text: this.$t('La acción se ha realizado correctamente')
          })
          const user = await this.$store.dispatch('auth/me')
          this.reservas = user.reservas_usuario_portal

          // Contamos las reservas pendientes
          let reservas_pendientes = 0
          this.reservas.forEach(
              current_reserva => {
                if (current_reserva.estado.id === 2) {
                  ++reservas_pendientes
                }
              }
          )
          if (reservas_pendientes === 0) {
            const resp = await this.$alert(
                this.$t('Ahora puedes realizar una nueva reserva'),
                '',
                'info'
            )
            if (resp) {
              await this.$router.replace({name: "centros"})
            }
          }
        } catch (err) {
          console.log(err)
        }
      }
    }
  },

  mounted() {
    this.reservas = this.$store.state.auth.user.reservas_usuario_portal
  }
}
</script>
